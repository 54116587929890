import * as React from 'react';
import {
    CalendarDate,
    GraduationHat02,
    FileShield03,
    MarkerPin01,
    Send01,
    Ticket01,
    Image03,
    PieChart03,
    Link04,
    Passport,
    Compass03,
    Announcement01,
    BarChartSquare03,
    Beaker02,
    Bookmark,
    Brush03,
    FileCheck02,
    FileCheck03,
    Globe04,
    Hash01,
    Link05,
    Map02,
    MarkerPin06,
    Users01,
} from '@untitled-ui/icons-react';
import { useLazyQuery } from '@apollo/client';
import { doesUrlMatchNavigationParts, Navigation, NavigationItem } from '@deltasierra/features/left-navigation';
import { t } from '@deltasierra/shared';
import { isNotNullOrUndefined } from '@deltasierra/type-utilities';
import {
    EnableNewGalleryPageFeatureFlagQuery,
    EnableNewGalleryPageFeatureFlagQueryVariables,
} from '../queries/__graphqlTypes/EnableNewGalleryPageFeatureFlagQuery';
import { ENABLE_NEW_GALLERY_PAGE_FEATURE_FLAG_QUERY } from '../queries/EnableNewGalleryPageFeatureFlag.query';
import { useAppFrontendRouter } from '../../../common/hooks/useAppFrontendRouter';
import { LeftNavigationView } from '../enums';
import { useUserBrandAccessCount } from './useUserBrandAccessCount';

type UseLeftNavigationMenuProps = {
    clientId: string | null;
    currentUrlPath: string;
    currentView: LeftNavigationView;
    locationId: string | null;
};

// eslint-disable-next-line max-lines-per-function
export function useLeftNavigationMenu({
    clientId,
    currentUrlPath,
    currentView,
    locationId,
}: UseLeftNavigationMenuProps): {
    isLoading: boolean;
    navigation: Navigation<React.ReactNode>;
} {
    const appFrontendRouter = useAppFrontendRouter();

    const [getFeatureFlagData, { data, loading }] = useLazyQuery<
        EnableNewGalleryPageFeatureFlagQuery,
        EnableNewGalleryPageFeatureFlagQueryVariables
    >(ENABLE_NEW_GALLERY_PAGE_FEATURE_FLAG_QUERY, {
        fetchPolicy: 'network-only',
    });

    React.useEffect(() => {
        if (clientId && locationId) {
            void getFeatureFlagData({
                variables: {
                    clientId,
                    locationId,
                },
            });
        }
    }, [clientId, getFeatureFlagData, locationId]);

    const { loading: userBrandCountLoading, userBrandCount } = useUserBrandAccessCount();

    const canAccessMoreThanOneBrand = isNotNullOrUndefined(userBrandCount) && userBrandCount > 1;

    const isNewGalleryPageEnabled: boolean =
        !loading &&
        (data?.me.features.enableNewGalleryPage ||
            data?.client?.features.enableNewGalleryPage ||
            data?.location?.features.enableNewGalleryPage ||
            false);

    // eslint-disable-next-line max-lines-per-function
    const navigation: Navigation<React.ReactNode> = React.useMemo(() => {
        const brandMenuSectionOneItems: Array<NavigationItem<React.ReactNode>> = [
            {
                icon: <Compass03 />,
                id: 'Overview',
                navigationUrl: `/brand/client/${clientId}/engagement/overview`,
                navigationUrlParts: {
                    endsWith: '/engagement/overview',
                    startsWith: '/brand/client/',
                },
                title: t('MENU.OVERVIEW'),
            },
            {
                icon: <BarChartSquare03 />,
                id: 'Location Activity',
                navigationUrl: `/brand/client/${clientId}/engagement/location-activity`,
                navigationUrlParts: {
                    endsWith: '/engagement/location-activity',
                    startsWith: '/brand/client/',
                },
                title: t('MENU.LOCATION_ACTIVITY'),
            },
            {
                icon: <FileCheck03 />,
                id: 'Template Use',
                navigationUrl: `/brand/client/${clientId}/engagement/template-use`,
                navigationUrlParts: {
                    endsWith: '/engagement/template-use',
                    startsWith: '/brand/client/',
                },
                title: t('MENU.TEMPLATE_USE'),
            },
            {
                icon: <Map02 />,
                id: 'Region/Group Activity',
                navigationUrl: `/brand/client/${clientId}/engagement/region-group-activity`,
                navigationUrlParts: {
                    endsWith: '/engagement/region-group-activity',
                    startsWith: '/brand/client/',
                },
                title: t('MENU.REGION_GROUP_ACTIVITY'),
            },
        ];

        if (canAccessMoreThanOneBrand) {
            brandMenuSectionOneItems.unshift({
                icon: <Passport />,
                id: 'Brands',
                navigationUrl: '/brand/agency/brands',
                navigationUrlParts: { startsWith: '/brand/agency/brands' },
                title: t('MENU.BRANDS'),
            });
        }

        const leftNavigator: Navigation<React.ReactNode> =
            currentView === LeftNavigationView.brand
                ? // Show left nav items for brand view.
                  [
                      {
                          id: 'SECTION 1',
                          isMaintainedOnClose: true,
                          items: brandMenuSectionOneItems,
                      },
                      {
                          hasDividerAbove: true,
                          headerTitle: t('MENU.COMMUNICATE'),
                          id: 'SECTION 2',
                          isMaintainedOnClose: false,
                          items: [
                              {
                                  icon: <Announcement01 />,
                                  id: 'Send',
                                  navigationUrl: '/brand/communicate/send',
                                  navigationUrlParts: {
                                      endsWith: '/send',
                                      startsWith: '/brand/communicate',
                                  },
                                  title: t('MENU.SEND'),
                              },
                              {
                                  icon: <FileCheck02 />,
                                  id: 'Report',
                                  navigationUrl: '/brand/communicate/send-report',
                                  navigationUrlParts: {
                                      endsWith: '/send-report',
                                      startsWith: '/brand/communicate',
                                  },
                                  title: t('MENU.REPORT'),
                              },
                          ],
                      },
                      {
                          hasDividerAbove: true,
                          headerTitle: t('MENU.LOCATIONS'),
                          id: 'SECTION 3',
                          isMaintainedOnClose: false,
                          items: [
                              {
                                  icon: <Ticket01 />,
                                  id: 'Special requests',
                                  navigationUrl: '/specialRequests',
                                  navigationUrlParts: { startsWith: '/specialRequests' },
                                  title: t('MENU.SPECIAL_REQUESTS'),
                              },
                              {
                                  icon: <MarkerPin06 />,
                                  id: 'Locations',
                                  navigationUrl: `/brand/client/${clientId}/manage/locations/locations`,
                                  navigationUrlParts: {
                                      endsWith: '/manage/locations/locations',
                                      startsWith: '/brand/client/',
                                  },
                                  title: t('MENU.LOCATIONS'),
                              },
                              {
                                  icon: <Globe04 />,
                                  id: 'Regions/Groups',
                                  navigationUrl: `/brand/client/${clientId}/manage/regions-groups/regions-groups`,
                                  navigationUrlParts: {
                                      endsWith: '/manage/regions-groups/regions-groups',
                                      startsWith: '/brand/client/',
                                  },
                                  title: t('MENU.REGIONS_GROUPS'),
                              },
                              {
                                  icon: <Users01 />,
                                  id: 'Location Users',
                                  navigationUrl: `/brand/client/${clientId}/manage/users/users`,
                                  navigationUrlParts: {
                                      endsWith: '/manage/users/users',
                                      startsWith: '/brand/client/',
                                  },
                                  title: t('MENU.LOCATION_USERS'),
                              },
                          ],
                      },
                      {
                          hasDividerAbove: true,
                          headerTitle: t('MENU.INTEGRATIONS'),
                          id: 'SECTION 4',
                          isMaintainedOnClose: false,
                          items: [
                              {
                                  icon: <Link05 />,
                                  id: 'Platforms',
                                  navigationUrl: `/brand/client/${clientId}/connections/platforms`,
                                  navigationUrlParts: {
                                      endsWith: '/connections/platforms',
                                      startsWith: '/brand/client/',
                                  },
                                  title: t('MENU.PLATFORMS'),
                              },
                              {
                                  icon: <Link04 />,
                                  id: 'Connect',
                                  navigationUrl: '/connect',
                                  navigationUrlParts: {
                                      startsWith: '/connect',
                                  },
                                  title: t('MENU.CONNECTIONS'),
                              },
                          ],
                      },
                      {
                          hasDividerAbove: true,
                          headerTitle: t('MENU.BRAND_SETTINGS'),
                          id: 'SECTION 5',
                          isMaintainedOnClose: false,
                          items: [
                              {
                                  icon: <Globe04 />,
                                  id: 'Clients',
                                  navigationUrl: '/agency/clients',
                                  navigationUrlParts: {
                                      startsWith: '/agency/clients',
                                  },
                                  title: t('MENU.CLIENTS'),
                              },
                              {
                                  icon: <GraduationHat02 />,
                                  id: 'Learn Menu',
                                  navigationUrl: `/brand/client/${clientId}/manage/learn-menu`,
                                  navigationUrlParts: {
                                      endsWith: '/manage/learn-menu',
                                      startsWith: '/brand/client/',
                                  },
                                  title: t('MENU.LEARN_MENU'),
                              },
                              {
                                  icon: <Beaker02 />,
                                  id: 'Calendar Activities',
                                  navigationUrl: `/brand/client/${clientId}/manage/activities`,
                                  navigationUrlParts: {
                                      endsWith: '/manage/activities',
                                      startsWith: '/brand/client/',
                                  },
                                  title: t('MENU.CALENDAR_ACTIVITIES'),
                              },
                              {
                                  icon: <Bookmark />,
                                  id: 'Template Categories',
                                  navigationUrl: `/brand/client/${clientId}/manage/template-categories`,
                                  navigationUrlParts: {
                                      endsWith: '/manage/template-categories',
                                      startsWith: '/brand/client/',
                                  },
                                  title: t('MENU.TEMPLATE_CATEGORIES'),
                              },
                              {
                                  icon: <Hash01 />,
                                  id: 'Hashtag Sets',
                                  navigationUrl: `/brand/client/${clientId}/manage/hashtags`,
                                  navigationUrlParts: {
                                      endsWith: '/manage/hashtags',
                                      startsWith: '/brand/client/',
                                  },
                                  title: t('MENU.HASHTAG_SETS'),
                              },
                              {
                                  icon: <Brush03 />,
                                  id: 'Styles',
                                  navigationUrl: `/brand/client/${clientId}/style`,
                                  navigationUrlParts: {
                                      endsWith: '/style',
                                      startsWith: '/brand/client/',
                                  },
                                  subItems: [
                                      {
                                          id: 'Fonts',
                                          navigationUrl: `/brand/client/${clientId}/style/fonts`,
                                          navigationUrlParts: {
                                              endsWith: '/style/fonts',
                                              startsWith: '/brand/client/',
                                          },
                                          title: t('MENU.FONTS'),
                                      },
                                      {
                                          id: 'Colors',
                                          navigationUrl: `/brand/client/${clientId}/style/colors`,
                                          navigationUrlParts: {
                                              endsWith: '/style/colors',
                                              startsWith: '/brand/client/',
                                          },
                                          title: t('MENU.COLORS'),
                                      },
                                      {
                                          id: 'Style Guide',
                                          navigationUrl: `/brand/client/${clientId}/style/style-guide`,
                                          navigationUrlParts: {
                                              endsWith: '/style/style-guide',
                                              startsWith: '/brand/client/',
                                          },
                                          title: t('MENU.STYLE_GUIDE'),
                                      },
                                  ],
                                  title: t('MENU.STYLES'),
                              },
                          ],
                      },
                  ]
                : // Show left nav items for location view.
                  [
                      {
                          id: 'SECTION 1',
                          isMaintainedOnClose: true,
                          items: [
                              {
                                  icon: <GraduationHat02 />,
                                  id: 'Learn',
                                  navigationUrl: '/trythis',
                                  navigationUrlParts: {
                                      startsWith: '/trythis',
                                  },
                                  title: t('MENU.LEARN'),
                              },
                              {
                                  icon: <CalendarDate />,
                                  id: 'Plan',
                                  navigationUrl: '/planner',
                                  navigationUrlParts: {
                                      startsWith: '/planner',
                                  },
                                  title: t('MENU.CALENDAR'),
                              },
                              {
                                  icon: <Send01 />,
                                  id: 'Scheduled',
                                  navigationUrl: '/scheduled',
                                  navigationUrlParts: {
                                      startsWith: '/scheduled',
                                  },
                                  title: t('MENU.SCHEDULED'),
                              },
                              {
                                  icon: <FileShield03 />,
                                  id: 'Templates',
                                  navigationUrl:
                                      appFrontendRouter && isNewGalleryPageEnabled && clientId && locationId
                                          ? appFrontendRouter.get(`/templates/?c=${clientId}&l=${locationId}`)
                                          : '/builderTemplateGallery',
                                  navigationUrlParts: {
                                      startsWith: '/builderTemplateGallery',
                                  },
                                  title: t('MENU.TEMPLATES'),
                              },
                              {
                                  icon: <Image03 />,
                                  id: 'Assets',
                                  navigationUrl: '/assets/view',
                                  navigationUrlParts: {
                                      startsWith: '/assets/view',
                                  },
                                  title: t('MENU.ASSETS'),
                              },
                              {
                                  icon: <PieChart03 />,
                                  id: 'Performance',
                                  navigationUrl: `/reports/location/${locationId}/overview`,
                                  navigationUrlParts: {
                                      endsWith: '/overview',
                                      startsWith: '/reports/location/',
                                  },
                                  title: t('MENU.PERFORMANCE'),
                              },
                              {
                                  icon: <Ticket01 />,
                                  id: 'Special requests',
                                  navigationUrl: '/specialRequests',
                                  navigationUrlParts: {
                                      startsWith: '/specialRequests',
                                  },
                                  title: t('MENU.REQUESTS'),
                              },
                              {
                                  icon: <Link04 />,
                                  id: 'Connect',
                                  navigationUrl: '/connect',
                                  navigationUrlParts: {
                                      startsWith: '/connect',
                                  },
                                  title: t('MENU.CONNECTIONS'),
                              },
                              {
                                  icon: <MarkerPin01 />,
                                  id: 'Location Details',
                                  navigationUrl: '/locationDetails',
                                  navigationUrlParts: {
                                      startsWith: '/locationDetails',
                                  },
                                  title: t('MENU.LOCATION_DETAILS'),
                              },
                          ],
                      },
                  ];

        const leftNavigatorWithCurrentSelection = leftNavigator.map(section => ({
            ...section,
            items: section.items.map(item => {
                const subItems = item.subItems?.map(subItem => ({
                    ...subItem,
                    isSelected:
                        subItem.navigationUrlParts &&
                        doesUrlMatchNavigationParts(currentUrlPath, subItem.navigationUrlParts),
                }));

                return {
                    ...item,
                    isSelected:
                        (item.navigationUrlParts &&
                            doesUrlMatchNavigationParts(currentUrlPath, item.navigationUrlParts)) ||
                        subItems?.some(subItem => subItem.isSelected),
                    subItems,
                };
            }),
        }));

        return leftNavigatorWithCurrentSelection;
    }, [
        appFrontendRouter,
        canAccessMoreThanOneBrand,
        clientId,
        currentUrlPath,
        currentView,
        isNewGalleryPageEnabled,
        locationId,
    ]);

    return { isLoading: loading || userBrandCountLoading, navigation };
}
