import { LEFT_NAV_BACKGROUND_COLOR, ToggleCollapseContainer } from '@deltasierra/components';
import { Navigation } from '@deltasierra/features/left-navigation';
import { t } from '@deltasierra/shared';
import {
    Box,
    Divider,
    Drawer,
    DrawerProps,
    List,
    ListItem,
    ListItemText,
    Toolbar,
    Typography,
    withStyles,
} from '@material-ui/core';
import { DotsHorizontal } from '@untitled-ui/icons-react';
import React from 'react';
import styled, { css } from 'styled-components';
import { LeftNavigationView } from '../enums';
import { MAX_NAVIGATION_WIDTH, MIN_NAVIGATION_HEIGHT, MIN_NAVIGATION_WIDTH } from '../constants';
import { LeftNavigationLeftDrawerItem } from './LeftNavigationLeftDrawerItem';

const StyledDrawer = withStyles({
    paper: {
        borderWidth: 0,
        overflowX: 'hidden',
        width: (props: DrawerProps) => (props.open ? MAX_NAVIGATION_WIDTH : MIN_NAVIGATION_WIDTH),
        zIndex: 1000,
    },
    root: {
        overflowX: 'hidden',
        width: (props: DrawerProps) => (props.open ? MAX_NAVIGATION_WIDTH : MIN_NAVIGATION_WIDTH),
    },
})(Drawer);

const StyledNavListScrollContainer = styled(Box)<{ $isOpenDrawer: boolean }>`
    height: 100%;
    margin-bottom: 16px;
    ${props =>
        props.$isOpenDrawer
            ? css`
                  overflow-y: scroll;
                  visibility: hidden;
                  &:hover {
                      visibility: visible;
                  }
              `
            : ''}
`;

const StyledNavListContainer = styled(Box)<{ $isOpenDrawer: boolean }>`
    height: 100%;
    ${props =>
        props.$isOpenDrawer
            ? css`
                  visibility: visible;
              `
            : ''}
`;

const StyledNavList = styled(List).attrs({ component: 'nav' })<{ $isOpenDrawer: boolean }>`
    padding-top: 0px;
    padding-bottom: 0px;
    & .left-nav-drawer-list-item-button {
        &:hover {
            background-color: ${({ theme }) => theme.palette.contextBackgrounds.primary};
        }
        border-radius: 8px;
        ${props =>
            props.$isOpenDrawer
                ? css`
                      margin-left: 6px;
                      margin-right: 6px;
                  `
                : css`
                      display: flex;
                      flex-direction: column;
                      justify-content: center;
                      margin-left: 2px;
                      margin-right: 2px;
                      margin-top: 0px;
                      margin-bottom: 0px;
                      padding-top: 6px;
                      padding-bottom: 6px;
                      width: 76px;
                  `}
    }
    & .left-nav-drawer-sub-list-item-button {
        &:hover {
            background-color: ${({ theme }) => theme.palette.contextBackgrounds.primary};
        }
        border-radius: 8px;
        margin-left: 6px;
        margin-right: 6px;
        min-height: 25px;
        padding-top: 0px;
        padding-bottom: 0px;
        padding-left: 16px;
        padding-right: 16px;
    }
    & .left-nav-drawer-list-item-text {
        color: ${({ theme }) => theme.palette.text.primary};
    }
    & .left-nav-drawer-list-sub-item-text {
        color: ${({ theme }) => theme.palette.text.primary};
    }
    & .left-nav-drawer-list-item {
        ${props =>
            props.$isOpenDrawer
                ? css`
                      display: flex;
                      min-height: 36px;
                      padding-left: 22px;
                      padding-right: 16px;
                      padding-top: 3px;
                      padding-bottom: 3px;
                  `
                : css`
                      display: flex;
                      flex-direction: column;
                      justify-content: center;
                  `}
    }
    & .left-nav-drawer-list-item-icon {
        ${props =>
            props.$isOpenDrawer &&
            css`
                margin-right: 24px;
            `}
    }
`;

const StyledDivider = styled(Divider)`
    margin-top: 10px;
    margin-bottom: 10px;
`;

const StyledAnchor = styled.a<{ $isOpenDrawer: boolean }>`
    cursor: pointer;
    ${props =>
        props.$isOpenDrawer
            ? css`
                  align-items: center;
                  display: flex;
                  width: 100%;
              `
            : css`
                  justify-items: center;
              `}
`;

const StyledHeader = styled(Typography).attrs({ variant: 'h5' })`
    color: ${({ theme }) => theme.palette.text.primary};
    font-size: 15px;
    font-weight: 600;
`;

const StyledMainListItem = styled(ListItem)<{ $isOpenDrawer: boolean; $isSelected: boolean }>`
    padding: 0px;
    ${props =>
        !props.$isOpenDrawer &&
        css`
            margin-bottom: 8px;
        `};
    ${props =>
        props.$isSelected &&
        css`
            & .left-nav-drawer-list-item-icon {
                color: ${({ theme }) => theme.palette.primary.main};
            }
            & .left-nav-drawer-list-item-text {
                color: ${({ theme }) => theme.palette.primary.main};
            }
        `};
`;

const StyledSubListItem = styled(ListItem)<{ $isSelected: boolean }>`
    ${props =>
        props.$isSelected &&
        css`
            & .left-nav-drawer-list-sub-item-text {
                color: ${({ theme }) => theme.palette.primary.main};
            }
        `};
`;

const RoundedCorner = styled(Box)<{ $isOpenDrawer: boolean }>`
    position: fixed;
    top: 60px;
    left: ${props => (props.$isOpenDrawer ? MAX_NAVIGATION_WIDTH : MIN_NAVIGATION_WIDTH)};
    z-index: 999;
    background-color: transparent;
    height: 25px;
    width: 25px;
    box-shadow: -12px -12px 0 0 ${LEFT_NAV_BACKGROUND_COLOR};
    border-top-left-radius: 12px;
`;

export type LeftNavigationLeftDrawerProps = {
    currentView: LeftNavigationView;
    isOpenDrawer: boolean;
    navigation: Navigation<React.ReactNode>;
    onClickMore: () => void;
};

/* eslint-disable max-lines-per-function */
export const LeftNavigationLeftDrawer = ({
    currentView,
    isOpenDrawer,
    navigation,
    onClickMore,
}: LeftNavigationLeftDrawerProps): JSX.Element => {
    const shouldShowMoreOption = !isOpenDrawer && currentView === LeftNavigationView.brand;

    return (
        <>
            <StyledDrawer open={isOpenDrawer} variant="permanent">
                <Toolbar style={{ minHeight: MIN_NAVIGATION_HEIGHT }} />
                <StyledNavListScrollContainer $isOpenDrawer={isOpenDrawer}>
                    <StyledNavListContainer
                        $isOpenDrawer={isOpenDrawer}
                        data-cy="left-navigation-left-drawer-nav-list-container"
                    >
                        {navigation.map(
                            section =>
                                (isOpenDrawer || section.isMaintainedOnClose) && (
                                    <Box key={section.id}>
                                        {isOpenDrawer && section.hasDividerAbove && <StyledDivider />}
                                        <StyledNavList $isOpenDrawer={isOpenDrawer} aria-label="main navigation">
                                            <>
                                                {isOpenDrawer && section.headerTitle && (
                                                    <ListItem classes={{ root: 'left-nav-drawer-list-item' }}>
                                                        <StyledHeader>{section.headerTitle}</StyledHeader>
                                                    </ListItem>
                                                )}
                                                {section.items.map(item => {
                                                    const showSubItems =
                                                        isOpenDrawer && !!item.subItems && item.subItems.length > 0;
                                                    return (
                                                        <StyledMainListItem
                                                            $isOpenDrawer={isOpenDrawer}
                                                            $isSelected={item.isSelected ?? false}
                                                            button
                                                            disableGutters
                                                            key={item.id}
                                                        >
                                                            <ToggleCollapseContainer
                                                                isDisabled={!showSubItems}
                                                                isOpenDefault
                                                                title={
                                                                    <LeftNavigationLeftDrawerItem
                                                                        icon={item.icon}
                                                                        isOpenDrawer={isOpenDrawer}
                                                                        link={item.navigationUrl}
                                                                        title={item.title}
                                                                    />
                                                                }
                                                            >
                                                                {showSubItems && (
                                                                    <List disablePadding>
                                                                        {item.subItems?.map(subItem => (
                                                                            <StyledAnchor
                                                                                $isOpenDrawer={isOpenDrawer}
                                                                                data-cy="left-navigation-left-drawer-item"
                                                                                href={subItem.navigationUrl}
                                                                                key={subItem.id}
                                                                            >
                                                                                <StyledSubListItem
                                                                                    $isSelected={
                                                                                        subItem.isSelected ?? false
                                                                                    }
                                                                                    button
                                                                                    classes={{
                                                                                        // eslint-disable-next-line max-len
                                                                                        root: 'left-nav-drawer-sub-list-item-button',
                                                                                    }}
                                                                                    disableGutters
                                                                                >
                                                                                    <ListItemText
                                                                                        classes={{
                                                                                            // eslint-disable-next-line max-len
                                                                                            root: 'left-nav-drawer-list-sub-item-text',
                                                                                        }}
                                                                                        primary={subItem.title}
                                                                                        primaryTypographyProps={{
                                                                                            style: {
                                                                                                fontSize: '11px',
                                                                                                fontStyle: 'normal',
                                                                                                fontWeight: 500,
                                                                                                lineHeight: 'normal',
                                                                                                paddingLeft: '54px',
                                                                                            },
                                                                                            variant: 'subtitle2',
                                                                                        }}
                                                                                    />
                                                                                </StyledSubListItem>
                                                                            </StyledAnchor>
                                                                        ))}
                                                                    </List>
                                                                )}
                                                            </ToggleCollapseContainer>
                                                        </StyledMainListItem>
                                                    );
                                                })}
                                                {shouldShowMoreOption && (
                                                    <LeftNavigationLeftDrawerItem
                                                        icon={<DotsHorizontal />}
                                                        isOpenDrawer={false}
                                                        title={t('MENU.MORE')}
                                                        onClick={onClickMore}
                                                    />
                                                )}
                                            </>
                                        </StyledNavList>
                                    </Box>
                                ),
                        )}
                    </StyledNavListContainer>
                </StyledNavListScrollContainer>
            </StyledDrawer>
            <RoundedCorner $isOpenDrawer={isOpenDrawer} />
        </>
    );
};
/* eslint-enable max-lines-per-function */

LeftNavigationLeftDrawer.displayName = 'LeftNavigationLeftDrawer';
