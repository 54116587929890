import { AssignedLocation } from '@deltasierra/shared';
import { LEFT_NAV_COLLAPSED_ENABLED_BODY_CLASS, LEFT_NAV_EXTENDED_ENABLED_BODY_CLASS } from '@deltasierra/components';
import { GraphqlService } from '../graphql/GraphqlService';
import { SELECTED_LOCATION_CHANGED_EVENT_NAME } from '../directives/locationPicker/locationPicker';
import { MvLocation } from '../locations/mvLocation';
import { $kookiesSID, IKookies } from '../common/angularData';
import { MvIdentity } from './mvIdentity';
import { GetNavbarFeatureFlag } from './LeftNavigation/queries/__graphqlTypes/GetNavbarFeatureFlag';
import { GET_NAVBAR_FEATURE_FLAG_QUERY } from './LeftNavigation/queries/GetNavbarFeatureFlag.query';
import { LEFT_NAVIGATION_ENABLED_COOKIE_NAME } from './LeftNavigation';

export class MvNavbar {
    public static readonly SID = 'mvNavbar';

    public static readonly $inject: string[] = [
        '$rootScope',
        MvIdentity.SID,
        GraphqlService.SID,
        MvLocation.SID,
        $kookiesSID,
    ];

    /*
        Initialise left navbar enabled state with data from cookie to prevent flickering between
        enabled/disabled states while loading.
    */
    public isLeftNavbarEnabled: boolean = this.loadStateFromCookie() ?? false;

    private location: AssignedLocation | null = null;

    public constructor(
        private readonly $rootScope: ng.IRootScopeService,
        private readonly mvIdentity: MvIdentity,
        private readonly graphqlService: GraphqlService,
        private readonly mvLocation: MvLocation,
        private readonly $kookies: IKookies,
    ) { }

    public init(): void {
        this.$rootScope.$watch(() => this.mvIdentity.isAuthenticated(), async () => {
            if (!this.location && this.mvIdentity.isAuthenticated()) {
                this.location = await this.mvLocation.getLocationOrUpdateWithDefault();
            }
            await this.updateIsLeftNavbarEnabled();
        });
        this.$rootScope.$on(SELECTED_LOCATION_CHANGED_EVENT_NAME, async (_event, data) => {
            this.location = data.location;
            await this.updateIsLeftNavbarEnabled();
        });
    }

    private loadStateFromCookie(): boolean | undefined {
        const cookieValue = this.$kookies.get(LEFT_NAVIGATION_ENABLED_COOKIE_NAME, Number);
        if (cookieValue === 1) {
            return true;
        } else if (cookieValue === 0) {
            return false;
        } else {
            return undefined
        }
    }

    private saveStateToCookie(isLeftNavbarEnabled: boolean): void {
        if (isLeftNavbarEnabled) {
            this.$kookies.set(
                LEFT_NAVIGATION_ENABLED_COOKIE_NAME,
                1,
                { domain: `.${window.location.hostname}`, path: '/' },
            );
        } else {
            this.$kookies.set(
                LEFT_NAVIGATION_ENABLED_COOKIE_NAME,
                0,
                { domain: `.${window.location.hostname}`, path: '/' },
            );
        }
    }

    private async updateIsLeftNavbarEnabled(): Promise<void> {
        if (this.mvIdentity.isAuthenticated() && this.location) {
            this.isLeftNavbarEnabled = this.loadStateFromCookie() ?? false;

            const { data } = await this.graphqlService
                .getClient()
                .query<GetNavbarFeatureFlag>({
                    fetchPolicy: 'network-only',
                    query: GET_NAVBAR_FEATURE_FLAG_QUERY,
                });

            this.isLeftNavbarEnabled = data.me.features.leftHandNavigation;

            if (!this.isLeftNavbarEnabled) {
                this.removeLeftNavigationBodyClasses();
            }

            this.saveStateToCookie(this.isLeftNavbarEnabled);
        }
    }

    private removeLeftNavigationBodyClasses(): void {
        if (document.body.classList.contains(LEFT_NAV_EXTENDED_ENABLED_BODY_CLASS)) {
            document.body.classList.remove(LEFT_NAV_EXTENDED_ENABLED_BODY_CLASS);
        }
        if (document.body.classList.contains(LEFT_NAV_COLLAPSED_ENABLED_BODY_CLASS)) {
            document.body.classList.remove(LEFT_NAV_COLLAPSED_ENABLED_BODY_CLASS);
        }
    }
}

angular.module('app').service(MvNavbar.SID, MvNavbar);
